<template>
  <div class="main">
    <!-- 搜索栏 -->
    <div class="list-tool-bar">
      <el-button-group class="action" style="width: 100%">
        <el-button :disabled="!delList.length" @click="onRead" type="primary">{{ t('system.read_button') }}</el-button>
        <el-button @click="onDelete" type="primary" plain>{{ t('system.del_all_button') }}</el-button>
      </el-button-group>
    </div>
    <!-- 站内通知 -->
    <custem-table
      :tableInfo="tableInfo"
      :tableLoading="tableLoading"
      @onSelectData="onSelectData"
      @getList="getList" />
  </div>
</template>

<script setup>
import { Notification, notificationRead, notificationDelete } from '@/utils/api'
import { onMounted, reactive, ref, inject } from 'vue'
import { ElMessageBox } from "element-plus"

const delList = ref([]) // 删除数据存放
const t = inject('t')
const tableLoading = ref(false)
// 标记已读
const onRead = () => {
  let ids = delList.value.map(item => {
    return item.uuid
  })
  notificationRead({
    ids: ids
  }).then(() => {
    getList()
    delList.value = []
  })
}

// 删除
const onDelete = () => {
  let tips = t('system.del_all'), subData = {}, ids = []
  if (delList.value.length) {
    ids = delList.value.map(item => {
      return item.uuid
    })
    tips = t('system.del_select')
    subData = { ids: ids }
  } else {
    subData = {
      ids: []
    }
  }
  ElMessageBox.confirm(
    tips,
    t('system.del_notice'),
    {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel'),
      type: 'warning',
    }
  ).then(() => {
    notificationDelete(subData).then(res => {
      getList()
      delList.value = []
    })
  }).catch()
}

// 接收多选数据
const onSelectData = (data) => {
  delList.value = data
}

const tableInfo = reactive({
  selection: true, // 选择框列
  header: [
    { // 标题内容
      label: t('system.title_content'),
      key: 'notice_title',
      minWidth: '320px'
    },
    { // 状态
      key: 'status',
      label: t('system.status'),
      width: '110px',
      sortable: true,
      sortBy: (data) => {
        return data.status
      },
      custem: (data) => {
        return data.status ? `<span style="color: #999">${t('system.read')}</span>` : `<span style="color: #000">${t('system.unread')}</span>`
      }
    },
    { // 提交时间
      key: 'created_at',
      width: '180px',
      label: t('table.submission_time'),
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      }
    },
    { // 类型
      key: 'notice_type',
      label: t('system.type'),
      width: '180px',
      custem: (data) => {
        return data.notice_type === 1 ? t('system.system_notice') : '-'
      }
    }
  ]
})

const getList = async () => {
  tableLoading.value = true
  try {
    const res = await Notification()
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>